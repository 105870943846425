<template>
    <div class="vvt-list">
        <List v-if="hasVvtApp" />
    </div>
</template>

<script>
import activeAppsMixin from '@/mixins/activeAppsMixin';
import List from '@/components/vvt/List';

export default {
    name: 'VvtListView',
    components: {
        List,
    },
    mixins: [activeAppsMixin],
};
</script>
