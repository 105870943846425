<template>
    <div>
        <template>
            <template v-if="$wait.is('fetch domains')">
                <v-skeleton-loader
                    v-for="n in 3"
                    :key="n"
                    loading
                    :type="'card-heading'"
                    tile
                    class="px-0 mx-0 my-0 mt-5 block"
                    width="100%"
                ></v-skeleton-loader>
            </template>
            <template v-else>
                <v-list
                    subheader
                    tile
                    dense
                >
                    <v-list-item-group v-model="selected" color="primary">
                        <v-list-item
                            :class="domainClassObject('all')"
                            class="mb-3"
                            :value="{id: 'all'}"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-t="'all'" />
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="domain in domains"
                            :key="domain.id"
                            :class="domainClassObject(domain.id)"
                            class="mb-3"
                            :value="domain"
                            :color="domain.color ? domain.color : null"
                        >
                            <template v-slot:default>
                                <v-list-item-icon>
                                    <v-icon
                                        v-if="domain.icon"
                                        :color="domain.color ? domain.color : null"
                                        v-text="domain.icon"
                                        />
                                    <div v-else style="width:24px">
                                        <div 
                                            class="rounded-circle w100 text-center white--text"
                                            :class="{'primary': !domain.color }"
                                            :style="{'background-color': domain.color ? domain.color : null}"
                                            v-text="domain.title.charAt(0).toUpperCase()" />
                                    </div>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ domain.title }} <span class="caption">({{ domain.processingActivityCount }})</span></v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FilterResultCompanies',
    props: {
        domains: {
            type: Array,
            default: () => []
        },
        selectedCompany: {
            type: Object,
            default: null
        },
        selectedDomain: {
            type: Object,
            default: null
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    computed: {
        selected: {
            get () {
                return this.selectedDomain;
            },
            set(domain) {
                if(domain && (!this.selectedDomain || !Object.prototype.hasOwnProperty.call(this.selectedDomain, 'id') || domain.id !== this.selectedDomain.id)) {
                    this.$emit('select-domain', domain);
                }
            }
        }
    },
    methods: {
        isActive(domainId) {
            return (
                this.selectedDomain && this.selectedDomain.id === domainId
            );
        },
        domainClassObject(domainId) {
            return {
                'v-list-item--active': this.isActive(domainId),
            };
        }
    },
};
</script>
