<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ $t('vvtList.title') }}
        </portal>
        <portal to="appBarNavigationSearch">
            <SearchRecord
                v-if="selectedCompany || selectedDomain"
                v-model="searchTerm"
                @input="searchRecords()"
            />
        </portal>
        <portal to="appBarNavigationAction">
            <div v-if="showMultiSelectionButton" class="d-inline-block ma-2">
                <v-menu :disabled="!multiSelectionMode || !selectedRecords || !selectedRecords.length" offset-y>
                    <template v-slot:activator="{ on: onMenu }">
                        <v-btn
                            @click="!selectedRecords || !selectedRecords.length ? multiSelectionMode = !multiSelectionMode : null"
                            v-on="onMenu"
                            color="white"
                            :class="{ 'primary--text': multiSelectionMode }"
                            :outlined="!multiSelectionMode"
                            fab
                            small
                            :elevation="2"
                            >
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <div v-on="{...onMenu, ...onTooltip}">
                                        <v-icon>
                                            <template v-if="!multiSelectionMode">mdi-check-box-multiple-outline</template>
                                            <template v-else-if="multiSelectionMode && selectedRecords && selectedRecords.length">mdi-dots-vertical</template>
                                            <template v-else-if="multiSelectionMode">mdi-checkbox-multiple-blank-outline</template>
                                        </v-icon>
                                    </div>
                                </template>
                                <span v-if="!multiSelectionMode" v-t="'vvtList.more.tooltip'"></span>
                                <span v-else-if="multiSelectionMode && selectedRecords && selectedRecords.length" v-t="'vvtList.more.actions.tooltip'"></span>
                                <span v-else-if="multiSelectionMode" v-t="'vvtList.more.selectMultipleRecords.tooltip'"></span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="$hasRole('ROLE_CREATOR')"
                            @click="duplicationSheet = true"
                        >
                            <v-list-item-title><v-icon left v-text="'mdi-plus-circle-multiple-outline'" />{{ $t('vvtList.more.actions.duplicate.label') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="$hasRole('ROLE_CONSULTANT')"
                            @click="bulkEditSheet = true"
                        >
                            <v-list-item-title><v-icon left v-text="'mdi-file-document-edit-outline'" />{{ $t('vvtList.more.actions.bulk.label') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="$hasRole('ROLE_DELETOR')"
                            @click="deletionSheet = true"
                        >
                            <v-list-item-title><v-icon left v-text="'mdi-trash-can-outline'" />{{ $t('vvtList.more.actions.delete.label') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-if="$hasRole('ROLE_EXPORTER') && selectedCompany && selectedCompany.id !== 'all'" class="d-inline-block ma-2">
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <v-btn
                                color="white"
                                outlined
                                fab
                                small
                                :elevation="2"
                                @click="exportDialog = true"
                            >
                                <v-icon
                                    color="white"
                                    v-text="'mdi-file-export-outline'"
                                    />
                            </v-btn>
                        </div>
                    </template>
                    <span v-if="$hasRole('ROLE_EXPORTER')" v-t="selectedFilterId && selectedFilterId !== 'all' ? 'vvtList.export.tooltipWithFilter' : 'vvtList.export.tooltip'" />
                    <span v-else v-t="'notAuthorizedAction'" />
                </v-tooltip>
            </div>
            <div v-if="$hasRole('ROLE_CREATOR')" class="d-inline-block ma-2">
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <v-btn
                                color="white"
                                outlined
                                fab
                                small
                                :elevation="2"
                                light
                                @click="$router.push({name: 'VvtProcessingActivityPool'})"
                            >
                                <v-icon v-text="'add'" />
                            </v-btn>
                        </div>
                    </template>
                    <span v-if="$hasRole('ROLE_CREATOR')" v-t="'vvtList.newActivity.tooltip'" />
                    <span v-else v-t="'notAuthorizedAction'" />
                </v-tooltip>
            </div>
        </portal>
        <template>
            <v-container
                fluid
                class="py-0 mb-0 pr-0"
                :class="{ 'mt-4': $vuetify.breakpoint.md }"
            >
                <v-row style="display:block;">
                    <LeftSidebar>
                        <div id="vvt-list-lft-header">
                            <VvtFilter
                                :selected="selectedFilterId"
                                @select="setFilter($event)"
                                @click-clear="refetchRecords()"
                                prepend-title
                                flat
                                style="padding-top:3px;border-bottom: 1px solid #e0e0e0;"
                                class="mt-3"
                            />
                            <v-subheader class="primary--text my-0 px-0">
                                <v-btn-toggle
                                    v-if="useDomainGrouping"
                                    v-model="grouping"
                                    tile
                                    color="primary"
                                    group
                                    mandatory
                                    class="mx-n1"
                                    @change="onChangedGrouping($event)"
                                >
                                    <v-btn value="domain" small v-t="'domains'" />
                                    <v-btn value="company" small v-t="'vvtList.companyColumn.title'" />
                                </v-btn-toggle>
                                <span
                                    v-else
                                    class="pt-3 pl-3 font-weight-bold text-truncate text-body-2"
                                    >{{ $t('vvtList.companyColumn.title') }}</span
                                >
                            </v-subheader>
                        </div>
                        <div :style="listStyle">
                            <template v-if="grouping === 'domain' && selectedFilterId">
                                <FilterResultDomains
                                    :domains="filteredDomainItems"
                                    :selected-domain="selectedDomain"
                                    :selected-company="selectedCompany"
                                    @select-domain="selectDomain($event)"
                                />
                            </template>
                            <template v-if="grouping === 'company' && selectedFilterId">
                                <FilterResultCompanies
                                    :companies="filteredCompanyItems"
                                    :selected-domain="selectedDomain"
                                    :selected-company="selectedCompany"
                                    @select-company="selectCompany($event)"
                                />
                            </template>
                        </div>
                    </LeftSidebar>
                    <template v-if="selectedFilterId">
                        <div style="margin-right: 12px;">
                            <FilterResultRecords
                                :record-items="recordItems"
                                :selectedRecord="selectedRecord"
                                :selected-filter-id="selectedFilterId"
                                :selectedRecords="selectedRecords"
                                @select-multiple-records="selectMultipleRecords($event)"
                                :multiSelectionMode="multiSelectionMode"
                                :totalRecordItems="totalRecordItems"
                                @select-record="selectRecord($event)"
                                @refetch-records="refetchRecords(false, $event)"
                                :page="page"
                                :grouping="grouping"
                                :selected-domain="selectedDomain"
                                :selected-company="selectedCompany"
                                :initial-table-sortby="getLocalStorageTableSortby()"
                                :initial-table-sort-desc="getLocalStorageTableSortDesc()"
                                :limit="limitFetchRecords"
                            />
                        </div>
                    </template>
                </v-row>
            </v-container>
            <v-navigation-drawer
                v-model="previewRecord"
                fixed
                temporary
                right
                :width="$rightSheetWidth()"
                disable-route-watcher
                @transitionend="previewRecordTransitionEnd($event)"
                class="v-navigation-drawer--right"
                >
                <RecordPreview
                    v-if="selectedRecord"
                    :toolbar-height="$vuetify.application.top"
                    :selected-record="selectedRecord"
                    :selected-filter="selectedFilter"
                    @deleteRecordConfirmation="setDeleteConfirmation($event)"
                    @close-record="closeRecord()"
                />
            </v-navigation-drawer>
            <BaseConfirmationDialog
                v-if="selectedRecord"
                v-model="deletionDialog"
                :title="$t('vvtList.actions.deleteConfirmationTitle')"
                :text="$t(deletionDialogText)"
                :confirmationText="$t('delete')"
                @confirm="deleteRecord()"
                :confirmationDisabled="!['archived', 'draft'].includes(selectedRecord.status.toLowerCase())"
                :maxWidth="$vuetify.breakpoint.smAndDown ? '95%' : '620px'"
                buttonColorConfirm="warning"
            />
            <v-dialog v-model="exportDialog" :width="$vuetify.breakpoint.mdAndUp ? 680 : '100%'">
                <VvtExportDialog
                    :exportType="exportTypeModel"
                    :exportLanguage="exportLanguageModel"
                    :exportFileType="exportFileTypeModel"
                    :exportHistoryDate="exportHistoryDateModel"
                    :selectedFilter="selectedFilter"
                    :minDate="minDate"
                    @close="exportDialog = false"
                    @process-export="processExport()"
                    @changeExportType="exportTypeModel = $event"
                    @changeLanguage="exportLanguageModel = $event"
                    @changeExportFileType="exportFileTypeModel = $event"
                    @changeExportHistoryDate="exportHistoryDateModel = $event"
                />
            </v-dialog>
            <v-navigation-drawer
                v-if="$hasRole('ROLE_CREATOR')"
                v-model="duplicationSheet"
                fixed
                temporary
                right
                :width="$rightSheetWidth()"
                disable-route-watcher
                class="v-navigation-drawer--right"
                >
                <DuplicateRecordsSidebar
                    flat
                    vertical
                    :toolbar-height="$vuetify.application.top"
                    :duplication-sheet="duplicationSheet"
                    :records="selectedRecords"
                    @multipleActionSuccess="multipleActionSuccess($event)"
                    @setDuplicationSheet="duplicationSheet = $event"
                    />
            </v-navigation-drawer>
            <v-navigation-drawer
                v-if="$hasRole('ROLE_CONSULTANT')"
                v-model="bulkEditSheet"
                fixed
                temporary
                right
                :width="$rightSheetWidth()"
                disable-route-watcher
                class="v-navigation-drawer--right"
                >
                <BulkEditSidebar
                    flat
                    vertical
                    :toolbar-height="$vuetify.application.top"
                    :bulk-edit-sheet="bulkEditSheet"
                    :records="selectedRecords"
                    @multipleActionSuccess="multipleActionSuccess($event)"
                    @setBulkEditSheet="bulkEditSheet = $event"
                    />
            </v-navigation-drawer>
            <v-navigation-drawer
                v-if="$hasRole('ROLE_DELETOR')"
                v-model="deletionSheet"
                fixed
                temporary
                right
                :width="$rightSheetWidth()"
                disable-route-watcher
                class="v-navigation-drawer--right"
                >
                <DeleteRecordsSidebar
                    flat
                    vertical
                    :toolbar-height="$vuetify.application.top"
                    :deletion-sheet="deletionSheet"
                    :records="selectedRecords"
                    @multipleActionSuccess="multipleActionSuccess($event)"
                    @setDeletionSheet="deletionSheet = $event"
                    />
            </v-navigation-drawer>
        </template>
        <v-snackbar
            v-model="successFeedbackMessage"
            color="success"
            app
            :timeout="6000"
            centered
        >
            <span v-text="successFeedbackMessageText" class="pr-3" />
            <v-btn dark text icon @click="successFeedbackMessage = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import http from '@/utils/axios';
import { mapGetters, mapActions } from 'vuex';
import titleMixin from '@/mixins/titleMixin';
const SearchRecord = () => import(/* webpackChunkName: "VvtSearch" */ '@/components/vvt/List/SearchRecord.vue');
const VvtFilter = () => import(/* webpackChunkName: "VvtFilter" */ '@/components/vvt/Filter.vue');
import FilterResultCompanies from './FilterResultCompanies.vue';
import FilterResultDomains from './FilterResultDomains.vue';
import FilterResultRecords from './FilterResultRecords.vue';
const LeftSidebar = () => import('./LeftSidebar.vue');
const RecordPreview = () => import('./RecordPreview/index.vue');
const VvtExportDialog = () => import(/* webpackChunkName: "VvtListExportDialog" */ './ExportDialog.vue');
const DuplicateRecordsSidebar = () => import(/* webpackChunkName: "VvtListDuplicateRecords" */ './DuplicateRecordsSidebar.vue');
const BulkEditSidebar = () => import(/* webpackChunkName: "VvtListBulkEdit" */ './Bulk/BulkEditSidebar.vue');
const DeleteRecordsSidebar = () => import(/* webpackChunkName: "VvtListDeleteRecords" */ './DeleteRecordsSidebar.vue');

const LEA_VVT_LIST_FILTER = 'LEA_VVT_LIST_FILTER';
const LEA_VVT_LIST_TABLE_PAGE = 'LEA_VVT_LIST_TABLE_PAGE';
const LEA_VVT_LIST_TABLE_SORTBY = 'LEA_VVT_LIST_TABLE_SORTBY';
const LEA_VVT_LIST_TABLE_SORTDESC = 'LEA_VVT_LIST_TABLE_SORTDESC';
const LEA_VVT_DASHBOARD_TABLE_SORTBY = 'LEA_VVT_DASHBOARD_TABLE_SORTBY';
const LEA_VVT_DASHBOARD_TABLE_SORTDESC = 'LEA_VVT_DASHBOARD_TABLE_SORTDESC';
const LIMIT_FETCH_RECORDS = 100;
export default {
    name: 'VvtList',
    components: {
        VvtFilter,
        FilterResultCompanies,
        FilterResultDomains,
        FilterResultRecords,
        LeftSidebar,
        RecordPreview,
        VvtExportDialog,
        SearchRecord,
        DuplicateRecordsSidebar,
        BulkEditSidebar,
        DeleteRecordsSidebar
    },
    mixins: [titleMixin],
    data() {
        return {
            page: this.getLocalStorageTablePage(),
            searchTerm: null,
            limitFetchRecords: LIMIT_FETCH_RECORDS,
            successFeedbackMessage: false,
            successFeedbackMessageText: null,
            duplicationSheet: false,
            bulkEditSheet: false,
            deletionSheet: false,
            selectedRecords: [],
            multiSelectionMode: false,
            selectedFilterId: null,
            previewRecord: false,
            selectedRecord: null,
            selectedCompany: { id: 'all', processingActivityCount: 0 },
            selectedDomain: { id: 'all', processingActivityCount: 0 },
            // COMPANY_TAB_PREVIEW: 1,
            // COMPANY_TAB_DATA_TRANSFER: 2,
            // companyNavigationModel: null,
            deletionDialog: false,
            exportDialog: false,
            exportTypeModel: null,
            exportLanguageModel: null,
            exportFileTypeModel: null,
            exportHistoryDateModel: null,
            deletionDialogText: null,
            grouping: 'company',
            tableItems: [],
            listMaxHeight: '100%',
            listStyle: null,
            minDate: null
        };
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    title() {
        if (this.grouping === 'domain' && this.selectedDomain) {
            if (this.selectedDomain.id === 'all') {
                return this.currentTitle;
            }
            return `${this.selectedDomain.title} - ${this.currentTitle}`;
        }
        if (this.grouping === 'company' && this.selectedCompany) {
            if (this.selectedCompany.id === 'all') {
                return this.currentTitle;
            }
            return `${this.selectedCompany.title} - ${this.currentTitle}`;
        }
        
        return this.currentTitle;
    },
    computed: {
        ...mapGetters({
            filterItems: 'filter/getAllFilter',
            getFilteredCompanies: 'companies/getFilteredCompanies',
            getFilteredDomains: 'domain/getFilteredDomains',
            getCompanyGroupSettings: 'companyGroupSettings/get',
        }),
        recordItems () {
            return this.tableItems.map(x => {
                    x.uniqueId = x.id + '_' + x.companyId;
                    return x;
            });
        },
        useDomainGrouping () {
            return this.getCompanyGroupSettings('useDomainGrouping');
        },
        showMultiSelectionButton () {
            if(this.grouping === 'domain') {
                return this.selectedDomain && (this.$hasRole('ROLE_CREATOR') || this.$hasRole('ROLE_DELETOR'));
            } else if(this.grouping === 'company') {
                return this.selectedCompany && (this.$hasRole('ROLE_CREATOR') || this.$hasRole('ROLE_DELETOR'));
            }
            return false;
        },
        currentTitle() {
            return this.$t('vvtList.title');
        },
        aliasItem () {
            return this.$route && this.$route.query && this.$route.query.filterAlias ? [{
                        id: -1,
                        title: this.$route.query.filterAlias
                  }] : [];
        },
        selectedFilter() {
            if (!this.selectedFilterId) {
                return null;
            }
            if (this.selectedFilterId === 'all') {
                return 'all';
            }
            return (
                this.aliasItem.concat(this.filterItems).find(x => x.id === this.selectedFilterId) ||
                null
            );
        },
        filteredCompanyItems() {            
            return this.getFilteredCompanies(this.selectedFilterId).sort((a, b) => {                
                if (a[this.companySortby()] < b[this.companySortby()]) {
                    return this.companySortDesc() ? 1 : -1;
                }
                if (a[this.companySortby()] > b[this.companySortby()]) {
                    return this.companySortDesc() ? -1 : 1;
                }
            });
        },
        filteredDomainItems() {            
            return this.getFilteredDomains(this.selectedFilterId).sort((a,b) => {
                if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
                if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
                return 0;
            })
        },
        totalRecordItems () {
            if(this.grouping === 'domain') {

                if (this.selectedDomain && this.selectedDomain.id === 'all') {
                    let recordsCount = 0;
                    this.filteredDomainItems.forEach(x => {
                        recordsCount += parseInt(x.processingActivityCount);
                    });
                    return recordsCount;
                }
                return this.selectedDomain && Object.prototype.hasOwnProperty.call(this.selectedDomain, 'processingActivityCount') ? this.selectedDomain.processingActivityCount : 0; 

            } else if(this.grouping === 'company') {

                if (this.selectedCompany && this.selectedCompany.id === 'all') {
                    let recordsCount = 0;
                    this.filteredCompanyItems.forEach(x => {
                        recordsCount += parseInt(x.processingActivityCount);
                    });
                    return recordsCount;
                }
                return this.selectedCompany && Object.prototype.hasOwnProperty.call(this.selectedCompany, 'processingActivityCount') ? this.selectedCompany.processingActivityCount : 0; 
            }
            return 0;
        }
    },
    methods: {
        ...mapActions({
            setEventStore: 'eventStore/set',
            fetchSettings: 'companyGroupSettings/fetch',
            fetchCompaniesByFilter: 'companies/fetchByFilter',
            fetchDomainsByFilter: 'domain/fetchByFilter',
            fetchFilteredRecords: 'companyProcessingActivities/fetchFilteredRecords',
            deleteActivity: 'companyProcessingActivities/delete'
        }),
        fetchMinDate () {
            let strDate = new Date();
            strDate.setDate(strDate.getDate() + 1);
            this.minDate = strDate.toISOString().substring(0, 10);
            if(this.selectedCompany) {
                this.$wait.start('loading min date');
                http.post('/api/fetchExportMinDate', JSON.stringify({classVersion: 2, companyId: this.selectedCompany.id, activityId: null}))
                .then(response => {
                    if (response.data) {
                        let strDate = new Date(response.data).toISOString().substring(0, 10);
                        this.minDate = strDate;
                        this.$wait.end('loading min date');
                    }
                })
                .catch(() => {})
            }
        },
        setListStyle() {
            const styleObj = {};
            this.listStyle = styleObj;
            let el = document.getElementById('vvt-list-lft-header');
            let leftSideBarHeaderHeight = 0;
            if(el) {
                //leftSideBarHeaderHeight = this.$outerHeight(el); //has margin issue
                leftSideBarHeaderHeight = 44 + 48;
            }
            if (this.listMaxHeight) {
                if (this.listMaxHeight === '100%') {
                    styleObj.height = `calc(100vh - ${this.$vuetify.application.top}px - ${this.$footerHeight()}px - ${leftSideBarHeaderHeight}px)`;
                } else {
                    styleObj.height = this.listMaxHeight + 'px';
                }
                styleObj.overflow = 'auto';
            }
            this.listStyle = styleObj;
            return styleObj;
        },
        onChangedGrouping(group) {
            if(this.$route.params.group !== group) {
                let newParams = {...this.$route.params, ...{group: group, groupId: null}};
                this.$router.push({
                    name: 'VvtList',
                    params: newParams,
                    query: this.$route.query
                });
                this.fetchFilteredGroupingAndRecords();
            }
        },
        companySortby () {
            let storageSortby = localStorage.getItem(LEA_VVT_DASHBOARD_TABLE_SORTBY);
            if (storageSortby === null || storageSortby === undefined) {
                return 'processingActivityCount';
            }
            return storageSortby;
        },
        companySortDesc () {
            let storageSortDesc = localStorage.getItem(LEA_VVT_DASHBOARD_TABLE_SORTDESC);
            if (storageSortDesc === null || storageSortDesc === undefined || storageSortDesc === 'true' || storageSortDesc === true) {
                return true;
            } else {
                return false;
            }
        },
        previewRecordTransitionEnd (e) {
            if (e && e.propertyName === 'visibility' && !this.previewRecord) {
                this.closeRecord();
            }
        },
        setDeleteConfirmation (event) {
            this.deletionDialogText = event.deletionText;
            this.deletionDialog = true;
        },
        selectMultipleRecords (val) {
            this.selectedRecords = val;
        },
        setFilter (filter) {

            if(this.$route.params.filterId !== filter) {  
                this.selectedRecords = [];
                this.$wait.start('fetch records');
                
                let objRouteParams = {};
                let objRouteQuery = {};
                
                objRouteParams.filterId = filter;
                if (this.$route.params.group) {
                objRouteParams.group = this.$route.params.group;
                
                }
                if(this.$route.params.groupId) {
                objRouteParams.groupId = this.$route.params.groupId;
                } else {
                objRouteParams.groupId = this.selectedCompany && this.selectedCompany.id !== 'all' ? this.selectedCompany.id : this.selectedDomain && this.selectedDomain.id !== 'all' ? this.selectedDomain.id : null;
                }
                
                if(this.$route.query.filterAlias && this.$route.query.filterId !== filter) {
                    objRouteQuery.filterAlias = this.$route.query.filterAlias;
                }
                
                if(this.$route.query.filterId !== filter) {
                    delete objRouteQuery.filterAlias;
                }


                
                
                if(!filter) {
                    delete objRouteParams.filterId;
                }

                localStorage.setItem(LEA_VVT_LIST_FILTER, filter);
                this.$router.push({
                    name: 'VvtList',
                    params: objRouteParams,
                    query: objRouteQuery
                });
            }
        },
        fetchCurrentFilterGrouping (filter, force) {
            return new Promise(resolve => {
                this.selectedFilterId = filter ? filter : 'all';
                if(this.grouping === 'domain') {
                    this.$wait.start('fetch domains');
                    this.fetchDomainsByFilter({ filterId: this.selectedFilterId, force: force, searchTerm: this.searchTerm }).then(result => {
                        this.$wait.end('fetch domains');
                        resolve(result.data);
                    });
                } else if (this.grouping === 'company') {
                    this.$wait.start('fetch companies');
                    this.fetchCompaniesByFilter({ filterId: this.selectedFilterId, force: force, searchTerm: this.searchTerm }).then(result => {
                        this.$wait.end('fetch companies');
                        resolve(result.data);
                    });
                }
            });
        },
        fetchRecords(groupingId) {
            this.tableItems = [];
            this.$wait.start('fetch records');
            if(this.grouping === 'domain') {
                this.selectedCompany = { id: 'all', processingActivityCount: 0 };
                if (groupingId === 'all') {
                    this.selectedDomain = { id: 'all', processingActivityCount: 0 };
                } else {
                    this.selectedDomain = this.filteredDomainItems.find(x => x.id === parseInt(groupingId)) ? this.filteredDomainItems.find(x => x.id === parseInt(groupingId)) : { id: 'all', processingActivityCount: 0 };
                }
            } else if (this.grouping === 'company') {
                this.selectedDomain = { id: 'all', processingActivityCount: 0 };
                if (groupingId === 'all') {
                    this.selectedCompany = { id: 'all', processingActivityCount: 0 };
                } else {
                    this.selectedCompany = this.filteredCompanyItems.find(x => x.id === parseInt(groupingId));
                }
            }
            const options = {
                searchTerm: this.searchTerm,
                filterId: this.selectedFilterId ? this.selectedFilterId : 'all',
                grouping: this.grouping,
                domainId: this.grouping === 'domain' && this.selectedDomain && this.selectedDomain.id ? this.selectedDomain.id : 'all',
                companyId: this.grouping === 'company' && this.selectedCompany && this.selectedCompany.id ? this.selectedCompany.id : 'all',
                page: this.getLocalStorageTablePage(),
                sortBy: this.getLocalStorageTableSortby(),
                sortDesc: this.getLocalStorageTableSortDesc(),
                limit: this.limitFetchRecords
            };
            this.fetchFilteredRecords(options).then(e => {
                this.tableItems = e;
                this.$wait.end('fetch records');
                if(this.grouping === 'domain') {
                    this.setEventStore({name: 'selectedDomainId', value: groupingId === 'all' ? null : this.selectedDomain.id});
                } else if (this.grouping === 'company') {
                    this.setEventStore({name: 'selectedCompanyId', value: groupingId === 'all' ? null : this.selectedCompany.id});
                }
            });
        },
        selectCompany(objCompany) {
            this.closeRecord();
            this.selectedRecords = [];
            let objRouteParams = {};
            let objRouteQuery = {};

            if (!this.selectedCompany || objCompany.id !== this.selectedCompany.id) {

                objRouteParams.group = 'company';
                if (this.selectedFilterId) {
                    objRouteParams.filterId = this.selectedFilterId
                }

                if (objCompany.id !== 'all') {
                    objRouteParams.groupId = parseInt(objCompany.id);
                    this.selectedCompany = this.filteredCompanyItems.find(x => x.id === parseInt(objCompany.id));
                } else {
                    this.selectedCompany = { id: 'all', processingActivityCount: 0 };
                }

                if(this.$route.query.filterAlias) {
                    objRouteQuery.filterAlias = this.$route.query.filterAlias;
                }

                this.$router.push({
                    name: 'VvtList',
                    params: objRouteParams,
                    query: objRouteQuery
                });
            }
        },
        selectDomain(objDomain) {
            this.closeRecord();
            this.selectedRecords = [];
            let objRouteParams = {};
            let objRouteQuery = {};

            if (!this.selectedDomain || objDomain.id !== this.selectedDomain.id) {

                objRouteParams.group = 'domain';
                if (this.selectedFilterId) {
                    objRouteParams.filterId = this.selectedFilterId
                }

                if (objDomain.id !== 'all') {
                    objRouteParams.groupId = parseInt(objDomain.id);
                    this.selectedDomain = this.filteredDomainItems.find(x => x.id === parseInt(objDomain.id));
                } else {
                    this.selectedDomain = { id: 'all', processingActivityCount: 0 };
                }

                if(this.$route.query.filterAlias) {
                    objRouteQuery.filterAlias = this.$route.query.filterAlias;
                }

                this.$router.push({
                    name: 'VvtList',
                    params: objRouteParams,
                    query: objRouteQuery
                });
            }
        },
        selectRecord(objRecord) {
            if (
                this.previewRecord &&
                this.selectedRecord &&
                this.selectedRecord.id === objRecord.id
            ) {
                this.closeRecord();
            } else {
                this.previewRecord = true;
                this.selectedRecord = objRecord;
            }
        },
        closeRecord() {
            this.previewRecord = false;
            this.selectedRecord = null;
        },
        deleteRecord() {
            const recordId = this.selectedRecord.id;
            const companyId = this.selectedRecord.companyId;
            this.closeRecord();
            this.deleteActivity({
                id: recordId,
                companyId: companyId,
            }).then(() => {
                this.refetchRecords();
            });
        },
        processExport() {
            this.exportDialog = false;
            if ( (['authority_controller', 'authority_processor'].includes(this.exportTypeModel) && (this.exportFileTypeModel === 'pdf' || this.exportFileTypeModel === 'word'))
                || (this.exportTypeModel === 'internal' && this.exportFileTypeModel === 'excel')
                || (this.exportTypeModel === 'monitoring' && this.exportFileTypeModel === 'excel')
            ) {
                this.$generateDownload({
                    companyName: this.selectedCompany.title,
                    recordId: null,
                    exportType: this.exportTypeModel,
                    contentType: this.exportFileTypeModel,
                    exportHistoryDate: this.exportHistoryDateModel,
                    language: this.exportLanguageModel,
                    companyId: this.selectedCompany.id,
                    filterId: this.selectedFilterId || null
                });
            }
        },
        fetchRecordsAfterRouteChange (toCompany, fromCompany, forceCompanyFetch) {
            this.$setStorage(LEA_VVT_LIST_TABLE_PAGE, 1);
            if (!toCompany) {
                this.refetchRecords(true);
            } else if (toCompany && (forceCompanyFetch || parseInt(toCompany) !== parseInt(fromCompany))) {
                this.refetchRecords(false, null, toCompany, fromCompany);
            }
        },
        fetchFilteredGroupingAndRecords () {
            return new Promise(resolve => {
                const filterId = this.$route.params && this.$route.params.filterId !== 'all' ? parseInt(this.$route.params.filterId) : 'all';
                this.fetchCurrentFilterGrouping(filterId, true).then((data) => {
                    this.fetchRecordsByRoute();
                    this.$nextTick(() => {
                        this.setListStyle();
                    })
                    resolve(data);
                });
            });
        },
        fetchRecordsByRoute() {
            if (!this.$route.params.groupId) {
                this.refetchRecords(true);
            } else {
                this.refetchRecords();
            }
        },
        multipleActionSuccess (successMessage) {
            this.fetchFilteredGroupingAndRecords();
            this.successFeedbackMessageText = this.$te(successMessage) ? this.$t(successMessage) : successMessage;
            this.successFeedbackMessage = true;
            this.selectedRecords = [];
            this.multiSelectionMode = false;
        },
        searchRecords () {
            this.fetchFilteredGroupingAndRecords().then(data => {
                const currentCompanyHasResult = this.$route.params.groupId && data.find(x => x.id === this.$route.params.groupId);
                if (!currentCompanyHasResult) {
                    this.selectCompany({ id:'all' });
                }
            });
        },
        async refetchRecords (forceAll, options, forceCompanyId, oldCompanyId) {
            this.$wait.start('refetch vvt list');
            if (forceAll) {
                await this.fetchRecords('all');
            } else if (forceCompanyId && oldCompanyId && parseInt(forceCompanyId) !== parseInt(oldCompanyId)) {
                await this.fetchRecords(parseInt(forceCompanyId));
            } else {
                await this.$nextTick();
                await this.fetchRecords(this.$route.params.groupId ? this.$route.params.groupId : 'all');
            }
            if(this.$hasRole('ROLE_EXPORTER')) {
                this.fetchMinDate();
            }
            this.$wait.end('refetch vvt list');
        },
        getLocalStorageTablePage () {
            let storagePage = localStorage.getItem(LEA_VVT_LIST_TABLE_PAGE);
            if (storagePage === null || storagePage === undefined) {
                return 1;
            }
            this.page = parseInt(storagePage);
            return parseInt(storagePage);
        },
        getLocalStorageTableSortby () {
            let storageSortby = localStorage.getItem(LEA_VVT_LIST_TABLE_SORTBY);
            if (storageSortby === null || storageSortby === undefined) {
                return 'updatedAt';
            }
            return storageSortby;
        },
        getLocalStorageTableSortDesc () {
            let storageSortDesc = localStorage.getItem(LEA_VVT_LIST_TABLE_SORTDESC);
            if (storageSortDesc === null || storageSortDesc === undefined || storageSortDesc === 'true' || storageSortDesc === true) {
                return true;
            } else {
                return false;
            }
        },
        async initMount() {
            this.$wait.start('fetch init');
            await this.fetchSettings().then(() => {
                if(this.useDomainGrouping && (this.$route.params.group === 'domain' || !this.$route.params.group)) {
                    this.grouping = 'domain';
                } else {
                    this.grouping = 'company';
                }
            });

            let storageFilter = localStorage.getItem(LEA_VVT_LIST_FILTER);
            if (!this.$route.params || !this.$route.params.filterId && storageFilter && storageFilter !== 'all' && storageFilter !== 'null') {
                this.replaceCurrentRoute(storageFilter);
            } else {
                this.replaceCurrentRoute();
            }

            await this.fetchFilteredGroupingAndRecords();
            this.$wait.end('fetch init');
        },
        replaceCurrentRoute(filterId = null, group = null, groupId = null, filterAlias = null) {
            let objRouteParams = {};
            let objRouteQuery = {};
            objRouteParams.filterId = filterId ? filterId : this.$route.params.filterId;

            if (group || this.$route.params.group) {
               objRouteParams.group = group ? group : this.$route.params.group;
               
            }
            if(groupId || this.$route.params.groupId) {
               objRouteParams.groupId = groupId ? groupId : this.$route.params.groupId;
            }

            if(filterAlias || this.$route.query.filterAlias) {
                objRouteQuery.filterAlias = filterAlias ? filterAlias : this.$route.query.filterAlias;
            }

            if(
                (filterId && this.$route.params.filterId !== filterId) ||
                (group && this.$route.params.group !== group) ||
                (groupId && this.$route.params.groupId !== groupId) ||
                (filterAlias && this.$route.query.filterAlias !== filterAlias)
            ) {
                this.$router.replace({ name: 'VvtList', params: objRouteParams, query: objRouteQuery });
            }
        }
    },
    beforeCreate () {
        this.$wait.start('fetch filter items');
        this.$wait.start('fetch records');
    },
    beforeDestroy () {
        this.$setStorage(LEA_VVT_LIST_TABLE_PAGE, 1);
        this.$removeClass(document.documentElement, 'disable-document-scrollbar');
    },
    mounted () {
        this.$addClass(document.documentElement, 'disable-document-scrollbar');
        this.initMount();
    },
    watch: {
        $route(to, from) {
            if (to.params.filterId === 'all') {
                // do not fetch same filtered companies again
                if (from.params.filterId !== 'all') {
                    this.fetchCurrentFilterGrouping('all', true).then(() => {
                        this.fetchRecordsAfterRouteChange(to.params.groupId, from.params.groupId);
                    });
                } else {
                    this.fetchRecordsAfterRouteChange(to.params.groupId, from.params.groupId);
                }
            } else {
                // do not fetch same filtered companies again
                if (parseInt(to.params.filterId) !== parseInt(from.params.filterId)) {
                    this.fetchCurrentFilterGrouping(parseInt(to.params.filterId), true).then(arrFilteredCompanies => {
                        /**
                         * Check, if the new filter has the last selected company, to reselect it.
                         */
                        const filterWithLastSelectedCompany = to.params.groupId ? arrFilteredCompanies.find(x => x.id === to.params.groupId) : null;
                        const reselectCompanyId = filterWithLastSelectedCompany ? filterWithLastSelectedCompany.id : null;
                        this.fetchRecordsAfterRouteChange(reselectCompanyId, from.params.groupId, true);
                    });
                } else {
                    this.fetchRecordsAfterRouteChange(to.params.groupId, from.params.groupId);
                }
            }
        },
    }
};
</script>
