import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            apps: 'apps/getApps',
        }),
        hasVvtApp() {
            return (
                !!this.apps.find(x => x.route.name === 'VvtList') === true
            );
        },
        hasCommunityApp() {
            return (
                !!this.apps.find(x => x.route.name === 'CommunityList') === true
            );
        },
    },
};
