<template>
    <div>
        <div v-if="selectedCompany && selectedCompany.id">
            <TableView
                :recordItems="recordItems"
                :selectedRecord="selectedRecord"
                :selectedRecords="selectedRecords"
                :multiSelectionMode="multiSelectionMode"
                :totalRecordItems="totalRecordItems"
                :page="page"
                :initialTableSortby="initialTableSortby"
                :initialTableSortDesc="initialTableSortDesc"
                :limit="limit"
                :grouping="grouping"
                :selected-domain="selectedDomain"
                :selected-company="selectedCompany"
                @select-multiple-records="$emit('select-multiple-records', $event)"
                @select-record="$emit('select-record', $event)"
                @refetch-records="$emit('refetch-records', $event)"
            />
        </div>
    </div>
</template>

<script>
import TableView from './TableView.vue';

export default {
    name: 'FilterResultRecords',
    components: {
        TableView
    },
    props: {
        selectedCompany: {
            type: Object,
            default: null
        },
        selectedDomain: {
            type: Object,
            default: null
        },
        selectedRecord: {
            type: Object,
            default: null
        },
        selectedFilterId: [Number, String],
        selectedRecords: {
            type: Array,
            default: () => []
        },
        multiSelectionMode: {
            type: Boolean,
            default: false
        },
        recordItems: {
            type: Array,
            default: () => []
        },
        totalRecordItems: {
            type: Number,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        },
        initialTableSortby: {
            type: String,
            default: null
        },
        initialTableSortDesc: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 10
        },
        grouping: {
            type: String,
            default: 'company'
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    data() {
        return {
            enableCheckbox: false
        };
    },
    computed: {
        companyScrollId() {
            return 'companyItem-' + this.selectedCompany.id;
        }
    }
};
</script>
